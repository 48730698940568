import React from "react";
import { readRemoteFile } from "react-papaparse";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TimerIcon from "@mui/icons-material/Timer";
import FlagIcon from "@mui/icons-material/Flag";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import "../src/modal.css";
import Modal from "react-modal";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ReactPlayer from "react-player";
import { Howl, Howler } from "howler";
import ReactHowler from "react-howler";

Howler.autoSuspend = false;
Howl.autoSuspend = false;

const customStyles = {
  content: {
    width: "80%",
    height: "70vh",
    margin: "auto",
    color: "white",
    background: "#212529",
    borderColor: "transparent",
  },
};

function App() {
  const [finalData, SetFinalData] = React.useState([]);
  const [movieSelected, setMovieSelected] = React.useState({});

  let scrollerID;
  let paused = true;
  let interval = 10;

  React.useEffect(() => {
    readRemoteFile(process.env.REACT_APP_FILE_PROGRAMA, {
      header: true,
      complete: (results) => {
        SetFinalData(results.data);
        console.log(results.data);
      },
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
          <br />
            <br />
            <ReactHowler
              src="https://audios.gironafilmfestival.com/credits_instrumental.mp3"
              playing={true}
              html5={true}
              preload={true}
              loop={true}
              volume={0.5}
            />
            <img
              src="https://www.gironafilmfestival.com/wp-content/uploads/2022/08/logo-blanco-girona.png"
              alt="GFF 34"
              width={70}
            />
            <br />
            <h4> Equipo GFF 34</h4>
            <br />
            <br />
          </div>
        </div>

        <div className="container">
          <div className="row">
            {finalData.map((persona, index) => (
              <div className="col-sm-6 mb-3" key={index}>
                <div className="card">
                  <div className="card-body text-black">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            src={
                              persona["Imagen"] == "" ||
                              persona["Imagen"] == "."
                                ? "https://www.gironafilmfestival.com/wp-content/uploads/2022/09/Sin-titulo-1920-×-1080-px.png"
                                : persona["Imagen"]
                            }
                            className="w-100 mb-2 imagen_1_1"
                            alt={persona["Nombre"]}
                          />
                        </div>
                        <div className="col-md-9 pt-4">
                          <strong>
                          {persona["Puesto"] == "" ? (
                            <>Girona Film Festival</>
                          ) : (
                            persona["Puesto"]
                          )}
                          </strong>
                          <br />
                          {persona["Nombre"] == "" ? (
                            <>Girona Film Festival</>
                          ) : (
                            persona["Nombre"]
                          )}

                          <br />
                          <a
                            href={persona["LinkedIn"]}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "black" }}
                          >
                            <LinkedInIcon />{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
